<template>
<v-navigation-drawer width="350" v-model="$store.state.main.navigationDrawerRightOpened" app temporary right fixed disable-resize-watcher :clipped="$vuetify.breakpoint.lgAndUp">
    <v-app-bar flat height="70">
        <v-spacer>
            <v-img width="200" src="/img/logo_sun_n30.png" />
        </v-spacer>
        <v-btn icon @click.stop="$store.commit('openCloseNavigationDrawerRight', false)">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-app-bar>

    <v-card flat tile dark color="primary">
        <v-list dense flat tile dark color="#5c5b5b" class="userinfo" v-if="contactInfo">
            <v-hover v-slot:default="{ hover }" open-delay="300" close-delay="300">
                <div>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon x-large :color="contactInfo.isConfirmed ? '' : 'orange lighten-2'">{{ contactInfo.isConfirmed ? 'how_to_reg' :'mdi-account-alert'}}</v-icon>
                            <!-- 'person_add_disabled' -->
                        </v-list-item-icon>
                        <v-list-item-content :style="!contactInfo.isConfirmed ? 'cursor: pointer':''">
                            <v-list-item-subtitle class="subtitle-1 font-weight-regular">Вітаємо,</v-list-item-subtitle>
                            <v-list-item-content class="pa-0 text-break title font-weight-regular">{{userInfo().on_behalf_of_user_id  ? userInfo().name : (contactInfo.contactName ? contactInfo.contactName :  userInfo().name)}}!</v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                    <transition name="fade">
                        <v-card dark class="ml-4 mr-4 mb-4" v-if="hover && !contactInfo.isConfirmed">
                            <v-card-text class="caption">
                                <p class="subtitle-1 white--text">Увага!<br>Ваші контактні данні не підтверджені.</p>
                                <p>Доступ на порталі до інформації може бути обмежений.</p>
                                <p>Для поновлення доступу зверніться, будь ласка, до керівника відділу:</p>
                                <p>
                                    <div class="orange--text">{{contactInfo.scManagerName}}</div>
                                    <div><a :href="`mailto:` + contactInfo.scManagerEmail">{{contactInfo.scManagerEmail}}</a></div>
                                    <div><a :href="`tel:` + contactInfo.scManagerPhone">{{contactInfo.scManagerPhone}}</a></div>
                                </p>
                                <p> </p>
                            </v-card-text>
                        </v-card>
                    </transition>
                </div>
            </v-hover>

            <v-divider class="mx-4 my-2"></v-divider>

            <v-list-item v-if="userInfo().on_behalf_of_user_id">
                <v-list-item-content>
                    <v-list-item-subtitle>Виконано вхід від імені клієнта</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-list-item v-if="userInfo().on_behalf_of_user_id" @click="setOnbehalf(true)" v-on="on" class="mb-4">
                        <v-list-item-icon>
                            <v-icon>mdi-account-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 font-italic">{{contactInfo.contactName}}</v-list-item-title>
                            <v-list-item-subtitle>Змінити клієнта</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <span>Змінити клієнта</span>
            </v-tooltip>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-html="contactInfo.clientName"></v-list-item-title>
                    <v-list-item-subtitle>Компанія</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="!contactInfo.isSellToEmployee" class="mx-4 my-2"></v-divider>

            <v-container class="py-2">
                <!-- <v-container v-if="!userInfo().on_behalf_of_user_id"> -->

                <v-row>
                    <v-col cols="6" class="py-0">
                        <v-list-item @click="goInto('/delivery-points')" v-if="checkAccess('cladmin_delivRecipient') && contactInfo.isConfirmed && !contactInfo.isSellToEmployee ? true : false" class="pa-0">
                            <v-list-item-title class="text-right">Точки доставки</v-list-item-title>
                            <v-list-item-avatar class="ma-0">
                                <v-btn icon>
                                    <v-icon>place</v-icon>
                                </v-btn>
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-list-item target="_blank" @click="goInto('/shops')" class="pa-0" v-if="checkAccess('cl_shops')  && !contactInfo.isSellToEmployee">
                            <v-list-item-title class="text-right">Магазини</v-list-item-title>
                            <v-list-item-avatar class="ma-0">
                                <v-btn icon>
                                    <v-icon>store</v-icon>
                                </v-btn>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-list-item @click="goInto('/contact-info')" class="pa-0" v-if="!userInfo().on_behalf_of_user_id">
                            <v-list-item-title class="text-right">Мій профіль</v-list-item-title>
                            <v-list-item-avatar class="ma-0">
                                <v-btn icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-btn>
                            </v-list-item-avatar>
                        </v-list-item>
                        <v-list-item target="_blank" @click="goInto('/contacts')" v-if="contactInfo.isMain  && !contactInfo.isSellToEmployee && !userInfo().on_behalf_of_user_id" class="pa-0">
                            <v-list-item-title class="text-right">Контакти</v-list-item-title>
                            <v-list-item-avatar class="ma-0">
                                <v-btn icon>
                                    <v-icon>contacts</v-icon>
                                </v-btn>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-container>

            <v-divider class="mx-4 my-2"></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Ваш менеджер</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-group v-model="manager" active-class="grey--text">
                <v-list-item slot="activator" class="px-0">
                    <v-list-item-avatar v-if="contactInfo.managerPhoto">
                        <v-img :src="'https://intranet.yugcontract.ua'+contactInfo.managerPhoto"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="d-flex justify-space-between">
                        <v-list-item-title>{{contactInfo.managerName}}</v-list-item-title>
                    </v-list-item-content>
                    <span v-if="!manager" class="mr-n6">
                        <v-icon class="mr-3">mdi-phone</v-icon>
                        <v-icon>email</v-icon>
                    </span>
                </v-list-item>
                <v-list-item v-if="contactInfo.managerPhone">
                    <v-list-item-icon>
                        <v-icon>mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle><a class="white--text" :href="'tel:'+contactInfo.managerPhone">{{contactInfo.managerPhone}}</a></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="contactInfo.managerCityPhone">
                    <v-list-item-icon>
                        <v-icon>mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle><a class="white--text" :href="'tel:'+contactInfo.managerCityPhone">{{contactInfo.managerCityPhone}}</a></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="contactInfo.managerEmail" class="mb-6">
                    <v-list-item-icon>
                        <v-icon>email</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-subtitle><a class="white--text" :href="'mailto:'+contactInfo.managerEmail">{{contactInfo.managerEmail}}</a></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <div class="px-4"><a @click="goInto('/manager')" class="grey--text caption ">
                    <v-icon color="grey" class="mb-1 mr-2">mdi-book-open-blank-variant</v-icon>контакти компанії
                </a></div>

            <v-divider class="mx-4 my-2"></v-divider>

            <div class="text-right px-4"><a href="https://yugcontract.ua/" class="grey--text caption " target="_blank">перейти на основний сайт<v-icon color="grey">arrow_right_alt</v-icon></a></div>

            <v-divider class="mx-4 my-2"></v-divider>

            <v-list-item>
                <v-list-item-title class="text-right">ВИЙТИ</v-list-item-title>
                <v-list-item-avatar class="my-0">
                    <v-btn icon @click="dialog = true">
                        <v-icon>logout</v-icon>
                    </v-btn>
                </v-list-item-avatar>
            </v-list-item>

        </v-list>
    </v-card>
    <v-divider></v-divider>
    <InfoBlock type="block" />
    <SaldoInformer />
    <DeptInformer />
    <v-list dense>
        <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action v-if="access(item)">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title>{{item.title}}
                        <v-icon class="pl-2" v-if="item.items.find(el=>el.new && access(el) )" color="primary">fiber_new</v-icon>
                    </v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item v-for="subItem in item.items" :key="subItem.title" :to="subItem.to" @click="goTo(subItem)" v-if="access(subItem)">
                <v-list-item-content>
                    <v-list-item-title class="font-weight-normal" v-text="subItem.title"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar v-if="subItem.new" class="ma-0">
                    <v-icon color="primary" class="ml-3">fiber_new</v-icon>
                </v-list-item-avatar>
                <v-list-item-avatar v-if="subItem.link" class="ma-0">
                    <v-icon small class="ml-4" title="Перехід на інший сайт">open_in_new</v-icon>
                </v-list-item-avatar>
            </v-list-item>
        </v-list-group>
    </v-list>

    <v-dialog v-model="dialog" max-width="600">
        <v-card>
            <v-card-title class="headline px-4">
                Завершити роботу
            </v-card-title>

            <v-card-text class="px-4 py-6">
                Ви впевнені, що бажаєте завершити роботу з В2В-порталом Юг-Контракт?
            </v-card-text>

            <v-card-actions>

                <v-btn text @click="dialog = false">
                    Скасувати
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="logout()">
                    Вийти
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <ExpiredBalanceDialog />
    <v-dialog v-model="dialogURL" persistent max-width="600">
        <v-system-bar color="primary" dark>
            <v-spacer></v-spacer>
            <v-icon v-if="$route.name != 'empty'" @click="resetURL()">mdi-close</v-icon>
        </v-system-bar>

        <v-card tile flat>
            <v-card-title class="px-2 px-sm-4">
                Перехід на попередню версію порталу
            </v-card-title>
            <v-card-text class="px-4 py-6">
                <p class="second--text">
                    Цей розділ ще не перенесено на новий портал.
                </p>
                <p class="second--text">
                    Вас буде перенаправлено на попередню версію порталу <a href="https://wholesale.yugcontract.ua/" target="_blank">https://wholesale.yugcontract.ua/</a> через:
                </p>
                <div class="text-center">
                    <v-progress-circular :size="130" :width="12" :value="timerV*10" color="primary">
                        {{ timerV }} сек
                    </v-progress-circular>
                </div>
            </v-card-text>
            <v-alert type="info" icon="vpn_key" outlined color="primary" class="mx-4 body-2">
                <span class="secondary--text">Увага! <b>Логін</b> та <b>пароль</b> – ті ж самі, що й до поточного порталу.</span>
            </v-alert>
            <v-card-actions>
                <v-btn text @click="goUrl()">
                    Перейти
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="resetURL()">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="underConstructionDialog" persistent width="600">
        <v-system-bar color="primary" dark>
            <v-spacer></v-spacer>
            <v-icon v-if="$route.name != 'empty'" @click="underConstructionDialog = false">mdi-close</v-icon>
        </v-system-bar>

        <v-card tile flat>
            <v-card-title class="px-2 px-sm-4">
                Розділ в розробцi
            </v-card-title>
            <v-card-text class="px-4 py-6">
                <p class="second--text">
                    Приносимо вибачення.
                </p>
                <p class="second--text">
                    Цей розділ в процесі розробки і скоро буде доступний на порталі.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="underConstructionDialog = false">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-navigation-drawer>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

import SaldoInformer from '@/components/SaldoInformer.vue'
import DeptInformer from '@/components/DeptInformer.vue'
import InfoBlock from './InfoBlock.vue'
import ExpiredBalanceDialog from './ExpiredBalanceDialog.vue'

export default {
    components: {
        SaldoInformer,
        DeptInformer,
        InfoBlock,
        ExpiredBalanceDialog
    },
    data: () => ({
        dialog: false,
        dialogURL: false,
        underConstructionDialog: false,
        timerId: NaN,
        timerVId: NaN,
        timerV: 0,
        url: '',
        manager: false,
    }),
    props: {},
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions(['getContactInfo', 'getUserInfo', 'logOut', 'touch', 'getSaldoInformer', 'getDeptInformer', 'getInfoBlocks','getContactMotivationList']),
        logout: function (params) {
            this.touch()
                .then(() => {
                    this.dialog = false
                    this.$store.commit('openCloseNavigationDrawerRight', false)
                    this.logOut()
                        .then(response => {
                            this.$router.push({
                                name: 'login'
                            })
                        })
                })
        },
        setOnbehalf(v) {
            this.$store.commit('setOnbehalfDialog', v)
        },
        goTo(v) {
            this.$store.commit('openCloseNavigationDrawerRight', false)
            // if (v.to) {
            //     if (this.$route.path != v.to) {
            //         this.$router.push(v.to)
            //     }
            // }
            //   Окно перенаправления
            // if (v.link) {
            //     this.url = v.link
            //     this.dialogURL = true
            //     this.timerV = 10
            //     this.timer()
            //     this.timerId = setTimeout(() => {
            //         this.goUrl()
            //     }, 11000);
            // }
            if (v.link) {
                this.url = v.link
                this.goUrl()
            }
        },
        underConstruction() {
            this.underConstructionDialog = true
        },
        goInto(v) {
            this.$store.commit('openCloseNavigationDrawerRight', false)
            if (this.$route.path != v) {
                this.$router.push(v)
            }
        },
        resetURL() {
            this.dialogURL = false
            clearTimeout(this.timerId)
            clearTimeout(this.timerVId)
        },
        goUrl() {
            this.dialogURL = false
            clearTimeout(this.timerId)
            clearTimeout(this.timerVId)
            window.open(this.url, "_self")
        },
        timer() {
            this.timerVId = setInterval(() => {
                this.timerV = this.timerV - 1
            }, 1000);
        },
        access(item) {
            const rule = this.contactInfo.groups && !item.hide && item.rules && item.rules.find(el => this.contactInfo.groups[el] == 1 || el == 'undef') ? true : false
            if (item.items) {
                let active = 0
                for (let el of item.items) {
                    if (this.access(el)) {
                        active = active + 1
                    }
                }
                if (!active) return false

            }
            return rule
        },
        checkAccess(group) {
            return this.contactInfo.groups && this.contactInfo.groups[group]
        },
        getData() {
            this.touch()
                .then(() => {
                    this.getUserInfo()
                    this.getContactInfo()

                    this.getInfoBlocks()
                        .then(() => this.getSaldoInformer())
                    this.getDeptInformer()
                    this.getContactMotivationList()
                })
                .catch(error => console.error(error))
        },
    },
    created() {
        this.getData()
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        motivationsList() {
            if (!this.$store.state.motivation.list || !this.$store.state.motivation.list.length) return []
            return this.$store.state.motivation.list.map(el => {
                return {
                    title: el.name,
                    to: `/motivation/${el.id}`,
                    rules: ['undef'],
                    new: 1
                }
            })
        },
        hideRule() {
            return this.contactInfo.isSellToEmployee ? true : false
        },
        hideRule2() {
            return this.contactInfo.isSellToEmployee || !this.contactInfo.realUserId ? true : false
        },
        hideRule3() {
            return this.userInfo() && (this.userInfo().id == 16896 || this.userInfo().id == 11 || this.userInfo().id == 713) ? false : true
        },
        items() {
            return [{
                    action: 'card_giftcard',
                    title: 'Акції та бонуси',
                    rules: ['undef','cl_corp_action', 'cl_corp_bonus'],

                    items: [{
                            title: 'Акції',
                            rules: ['cl_corp_action'],
                            to: '/actions',
                        },
                        {
                            title: 'Бонусні програми',
                            rules: ['cl_corp_bonus'],
                            to: '/bonus',
                            hide: this.hideRule
                        },
                        ...this.motivationsList
                    ],
                },
                {
                    action: 'list_alt',
                    title: 'Замовлення товарів',
                    active: false,
                    rules: ['undef'],
                    hide: this.hideRule,
                    items: [{
                            title: 'ТОП пропозиції',
                            rules: ['undef'],
                            to: '/shopping-guide',
                            new: 0,
                            hide: this.hideRule2
                        },
                        {
                            title: '"Пакетне" додавання товарів',
                            rules: ['undef'],
                            to: '/shopping-packet',
                            new: 0
                        },
                        {
                            title: 'Прайс-листи',
                            rules: ['undef'],
                            to: '/price',
                        },
                        // {
                        //     title: 'Опис товарів каталогу',
                        //     rules: ['undef'],
                        //     to: '/content',
                        // },
                        {
                            title: 'Поточні резерви',
                            rules: ['cl_cur_reserve'],
                            to: '/reserve'
                        },
                        {
                            title: 'Історія замовлень',
                            rules: ['cl_sales'],
                            to: '/sales',
                        },
                        {
                            title: 'Історія товарного обігу',
                            rules: ['cl_sales'],
                            to: '/salesgoods',
                        },
                    ],
                },
                {
                    action: 'api',
                    title: 'API для клієнтів',
                    rules: ['undef'],
                    hide: this.hideRule,
                    items: [{
                            title: 'Інтеграція з API Юг-контракт',
                            rules: ['undef'],
                            to: '/api-start'
                        },
                        {
                            title: 'Інтерфейси API',
                            rules: ['undef'],
                            to: '/api-services'
                        },
                        {
                            title: 'Вивантаження для Prom.ua (beta)',
                            rules: ['undef'],
                            new: 1,
                            to: '/promua-price-list'
                        },
                        {
                            title: 'Налаштування API',
                            rules: ['undef'],
                            to: '/api-settings'
                        },
                        // {
                        //     title: 'Попередня версія API',
                        //     rules: ['cladmin_soap'],
                        //     link: 'https://wholesale.yugcontract.ua/soap'
                        // },
                    ],
                },
                {
                    action: 'attach_money',
                    title: 'Фінанси',
                    rules: ['undef'],
                    hide: this.hideRule,
                    items: [{
                            title: 'Реквізити для оплати',
                            rules: ['undef'],
                            to: '/payment-details'
                        },
                        {
                            title: 'Баланс',
                            rules: ['cladmin_accounts'],
                            to: '/balance'
                        },
                        {
                            title: 'Графік погашення боргу',
                            rules: ['cl_payment'],
                            to: '/payment',
                        },
                    ],
                },
                {
                    action: 'mdi-file-chart',
                    title: 'Завантаження звітів',
                    rules: ['undef'],
                    hide: this.hideRule,
                    items: [
                        {
                            title: 'Звіт про продажі по IMEI',
                            rules: ['undef'],
                            to: '/imei-reports',
                        },
                        {
                            title: 'Національне Промо Samsung!',
                            rules: ['undef'],
                            to: '/imei-14-reports',
                            new: 1
                        },
                        {
                            title: 'Звіт про залишки за період',
                            rules: ['undef'],
                            to: '/stock-reports',
                        },
                        {
                            title: 'Звіт про продажі за період',
                            rules: ['undef'],
                            to: '/sales-reports',
                        },
                    ],
                },
                {
                    action: 'construction',
                    title: 'Сервісний центр',
                    rules: ['undef'],
                    // hide: this.hideRule,
                    items: [{
                            title: 'Сервісний гід',
                            rules: ['undef'],
                            to: '/service-help',
                        },
                        {
                            title: 'Регламентні документи',
                            rules: ['undef'],
                            to: '/service-docs',
                        },
                        {
                            title: 'Історія ремонту товарів',
                            rules: ['cl_corp_repair'],
                            to: '/repair',
                        },
                        {
                            title: 'Сертифікати товарів',
                            rules: ['undef'],
                            to: '/certificates',
                        },
                        {
                            title: 'Перевірка s/n (IMEI)',
                            rules: ['undef'],
                            link: '/checksn'
                        },
                    ],
                },
                // {
                //     action: 'gavel',
                //     title: 'Аукціон',
                //     rules: ['undef'],
                //     hide: this.hideRule,
                //     items: [{
                //         title: 'Торги по лотам',
                //         rules: ['undef'],
                //         link: 'https://wholesale.yugcontract.ua/auction/'
                //     }, ],
                // },
                // {
                //     action: 'email',
                //     title: 'Розсилки',
                //     rules: ['undef'],
                //     hide: this.hideRule,
                //     items: [{
                //             title: 'Керування підпискою',
                //             rules: ['undef'],
                //             link: 'https://wholesale.yugcontract.ua/subscribe'
                //         },
                //         {
                //             title: 'Архів розсилок',
                //             rules: ['undef'],
                //             link: 'https://wholesale.yugcontract.ua/subscribe/archive/'
                //         },
                //     ],
                // },
                {
                    action: 'mdi-handshake',
                    title: 'Умови співробітництва',
                    rules: ['24_7User'],
                    hide: this.hideRule,
                    items: [{
                        title: 'Умови доставки',
                        rules: ['undef'],
                        to: '/delivery',
                    }, ],
                },
                // {
                //     action: 'info',
                //     title: 'Довідкова інформація',
                //     rules: ['undef'],
                //     items: [{
                //         title: 'Презентації продуктів',
                //         rules: ['undef'],
                //         link: 'https://wholesale.yugcontract.ua/library/'
                //     }, ],
                // },
                {
                    action: 'mdi-application-cog-outline',
                    title: 'ADMIN Керування банерами',
                    rules: ['undef'],
                    hide: this.hideRule3,
                    items: [{
                            title: 'Банери',
                            rules: ['undef'],
                            to: '/banners'
                        },
                        {
                            title: 'Розміщення банерів',
                            rules: ['undef'],
                            to: '/banners-placement'
                        },
                    ],
                },
            ]
        }
    },
    watch: {
        '$store.state.main.navigationDrawerRightOpened'(val) {
            if (val) {
                this.getData()
            }
        },
    },

}
</script>

<style scoped>
.userinfo .v-list-item__action:first-child,
.userinfo .v-list-item__icon:first-child {
    margin-right: 13px !important;
    margin-top: 13px !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0
}
</style>
