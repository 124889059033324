import 'babel-polyfill'
import Vue from 'vue'
import './plugins/axios'
import "./vee-validate"

import vuetify from './plugins/vuetify'
//import socket from './plugins/socket.client.js'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6LeFpVYdAAAAAEkuPYWCLWJiRvcMZhdnDI08zGZH'})

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import {HTTP} from './http-common'
Vue.prototype.$http = HTTP

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

Vue.config.productionTip = false

Vue.config.devtools = false;

Vue.config.errorHandler = function(err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}

Vue.config.warnHandler = function(msg, vm, trace) {
  console.log(`Warn: ${msg}\nTrace: ${trace}`);
}

window.onerror = function(message, source, line, column, error) {
  console.log('ONE ERROR HANDLER TO RULE THEM ALL:', message);
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

