<template>
<v-app id="app">
    <!-- Main Toolbar -->
    <Toolbar v-if="
$route.name !== null &&
$route.name !== 'login' &&
$route.name !== 'NewClient' &&
$route.name !== 'Forgot' &&
$route.name !== 'Checkout' &&
$route.name !== 'BalanceDirect' &&
$route.name !== 'Checkout-success' &&
$route.name !== 'Checkout-preorder-success' &&
$route.name !== 'CheckoutPreorder' &&
$route.name !== 'empty' &&
$route.name !== 'emptyClient' &&
$route.name !== 'checksn' &&
$route.name !== 'good' &&
$route.name !== 'goodid' &&
$route.name !== 'goodT22' &&
showToolbar
" />

    <v-progress-linear fixed :active="$store.state.main.loading" color="error" :indeterminate="true" class="linear ma-0" height="3"></v-progress-linear>

    <!-- Routed content -->
    <v-main :style="$route.name !== 'home' && $vuetify.breakpoint.lgAndUp ? `padding-left: 55px;` : ``">
        <router-view />
    </v-main>

    <!-- Total used SnackBar     -->
    <v-snackbar v-model="snackbar().model" color="transparent" elevation="0" :timeout="snackbar().timeout || 4000">
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar().model = false" icon class="ml-n2">
                <v-icon color="second">mdi-close</v-icon>
            </v-btn>
        </template>
        <v-alert :color="snackbar().color" :icon="snackbar().icon || 'mdi-alert'" class="body-2 ma-0 mb-1">
            {{ snackbar().text }}
        </v-alert>
        <v-alert v-if="snackbar().alert" type="warning" icon="mdi-alert" class="body-2" color="orange">
            {{ snackbar().alert }}
        </v-alert>

    </v-snackbar>

    <v-fab-transition>
        <v-btn v-if="this.$vuetify.breakpoint.smAndDown && scrolled && $route.name !== 'Product'" color="primary" dark fixed bottom right fab @click="$vuetify.goTo(0)">
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn v-if="this.$vuetify.breakpoint.smAndUp && scrolled " color="primary" dark fixed bottom right fab @click="$vuetify.goTo(0)">
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
    </v-fab-transition>

    <!-- Footer -->
    <v-footer>
        <v-spacer></v-spacer>
        <div>Юг-Контракт &copy; {{ new Date().getFullYear() }}</div>
        <v-spacer></v-spacer>
    </v-footer>

    <!-- Onbehalf Dialog -->
    <OnbehalfDialog />
    <!-- Open Client Dialog -->
    <EmptyClientDialog />
</v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import OnbehalfDialog from '@/components/OnbehalfDialog.vue'
import EmptyClientDialog from '@/components/EmptyClientDialog.vue'
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    name: 'App',
    components: {
        Toolbar,
        OnbehalfDialog,
        EmptyClientDialog,
    },
    data() {
        return {
            ...mapGetters(['userInfo', 'snackbar']),
            windowWidth: 0,
            footer: {
                inset: true,
            },
            scrolled: false,
        }
    },
    methods: {
        ...mapActions(['printScreen']),
        handleScroll() {
            this.scrolled = window.scrollY > 250
        },
    },
    watch: {},
    async mounted() {
        await this.$recaptchaLoaded()
        const recaptcha = this.$recaptchaInstance
        // Hide reCAPTCHA badge:
        recaptcha.hideBadge()

        document.addEventListener('keyup', (e) => {
            let es = window.event || e
            if (es.keyCode == 44) {
                this.printScreen(this.$route.path)
            }
        })
    },
    computed: {
        showToolbar() {
            return this.userInfo().id > 0 ? true : false
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style>
html {
    font-weight: 400;
}

.linear {
    z-index: 1000;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.v-snack__content {
    padding-bottom: 0px;
}

.v-snack__action {
    align-self: start;
}
</style>
