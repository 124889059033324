import {
    HTTP
} from '../http-common'

export default {
    state: {
        loading: false
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
    },
    actions: {
        //deprecated
        searchServiceProduct({
            commit
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/service/search-service-product', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getServiceData({
            commit
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/service/get-service-data', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getRepairList({
            commit
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/service/get-repair-list', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getServiceContractors({
            commit
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/service/get-contractors', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getRepairListXlsx({
            commit
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/service/repair-xlsx', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendRequestMessage({
            commit
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/service/send-request-message', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
    }
}