import {
    HTTP,
    HTTP_PRODUCT
} from '../http-common'


export default {
    state: {
        catalogList: [],
        catalogListTotal: 0,
        analogsList: [],
        analogsListTotal: 10,
        duPriceoffList: [],
        duPriceoffListTotal: 10,
        listLoading: false,
        priceMin: 0,
        priceMax: 1000,
        parentCats: [],
        actionCats: [],
        catalogListBrands: [],
        catalogOptions: {},
        clientsAutocomplete: [],
        contactsAutocomplete: [],
        sliderHotItems: [],
        sliderNewItems: [],
        recommendedItems: [],
        visitedItems: [],
        bonusesItems: [],
        actionsItems: [],
        nestedCats: [],
        props: [],
        propsData: {},
        propsNumeric: [],
        propsNumericData: {},
        filtersLoaded: false,
        productData: {},
        productInfoData: {},
        openProductData: {},
        analogsData: [],
        analogsTotal: 0,
        inFindIds: [],
        filtersDrawerOpened: false,
        sertificates: [],
        shoppingGuideList: [],
        shoppingGuideActionsList: [],
        shoppingGuideActionsListTotal: 0,
        shoppingGuideTasksList: [],
        shoppingTasksDrawerOpened: false,
        shoppingTasksPagination: false,
        shoppingTasksFilters: [],
        shoppingTasksFilterModel: {},
        shopsList: [],
        shopsFormats: [],
        actionGroup: null,
        actionProdset: null,
        fullPriceBlock: false,
        openProductDataError: false,
        giftsData: {},
        groupGifts: {},
        goodsPrizes: {},
        shoppingCartGiftsData: {},
        catalogListArchiveTotal: 0,
        catalogListArchive: [],
        archiveSearchProcess: false,
        accumulativeActionsList: [],
        accumulativeActionId: null,
        accumulativeActionData: {},
        snData: []
    },
    mutations: {
        setCatalogList(state, payload) {
            state.catalogList = payload
        },
        setCatalogListTotal(state, payload) {
            state.catalogListTotal = payload
        },
        setAnalogsList(state, payload) {
            state.analogsList = payload
        },
        setAnalogsListTotal(state, payload) {
            state.analogsListTotal = payload
        },
        setDuPriceoffList(state, payload) {
            state.duPriceoffList = payload
        },
        setDuPriceoffListTotal(state, payload) {
            state.duPriceoffListTotal = payload
        },
        setPriceMin(state, payload) {
            state.priceMin = payload
        },
        setPriceMax(state, payload) {
            state.priceMax = payload
        },
        setCatalogListBrands(state, payload) {
            state.catalogListBrands = payload
        },
        setCatalogOptions(state, payload) {
            state.catalogOptions = payload
        },
        setClientsAutocomplete(state, payload) {
            state.clientsAutocomplete = payload
        },
        setContactsAutocomplete(state, payload) {
            state.contactsAutocomplete = payload
        },
        setSliderHotItems(state, payload) {
            state.sliderHotItems = payload
        },
        setSliderNewItems(state, payload) {
            state.sliderNewItems = payload
        },
        setRecommendedItems(state, payload) {
            state.recommendedItems.push(...payload)
        },
        setVisitedItems(state, payload) {
            state.visitedItems = payload
        },
        setActionsItems(state, payload) {
            state.actionsItems = payload
        },
        setBonusesItems(state, payload) {
            state.bonusesItems = payload
        },
        setNestedCats(state, payload) {
            state.nestedCats = payload
        },
        setProps(state, payload) {
            state.props = payload
        },
        setPropsData(state, payload) {
            state.propsData = payload
        },
        setPropsNumeric(state, payload) {
            state.propsNumeric = payload
        },
        setPropsNumericData(state, payload) {
            state.propsNumericData = payload
        },
        setProductData(state, payload) {
            state.productData = payload
        },
        setProductInfoData(state, payload) {
            state.productInfoData = payload
        },
        setAnalogsData(state, payload) {
            state.analogsData = payload
        },
        setAnalogsTotal(state, payload) {
            state.analogsTotal = payload
        },
        setParentCats(state, payload) {
            state.parentCats = payload
        },
        setActionCats(state, payload) {
            state.actionCats = payload
        },
        setCataloginFindIds(state, payload) {
            state.inFindIds = payload
        },
        openCloseFiltersDrawer(state, payload) {
            state.filtersDrawerOpened = payload
        },
        setOpenProductData(state, payload) {
            state.openProductData = payload
        },
        setSertificates(state, payload) {
            state.sertificates = payload
        },
        setShoppingGuideActionsList(state, payload) {
            state.shoppingGuideActionsList = payload
        },
        setShoppingGuideActionsListTotal(state, payload) {
            state.shoppingGuideActionsListTotal = payload
        },
        setShoppingGuideList(state, payload) {
            state.shoppingGuideList = payload
        },
        setShoppingGuideTasksList(state, payload) {
            state.shoppingGuideTasksList = payload
        },
        openCloseShoppingTasksDrawer(state, payload) {
            state.shoppingTasksDrawerOpened = payload
        },
        setShoppingTasksPagination(state, payload) {
            state.shoppingTasksPagination = payload
        },
        setShoppingTasksFilters(state, payload) {
            state.shoppingTasksFilters = payload
        },
        setShoppingTasksFilterModel(state, payload) {
            state.shoppingTasksFilterModel = payload
        },
        setShopsList(state, payload) {
            state.shopsList = payload
        },
        setShopsFormats(state, payload) {
            state.shopsFormats = payload
        },
        setActionGroup(state, payload) {
            state.actionGroup = payload
        },
        setFullPriceBlock(state, payload) {
            state.fullPriceBlock = payload
        },
        setOpenProductDataError(state, payload) {
            state.openProductDataError = payload
        },
        setGroupGifts(state, payload) {
            state.groupGifts = payload
        },
        setActionProdset(state, payload) {
            state.actionProdset = payload
        },
        setGiftsData(state, payload) {
            state.giftsData = payload
        },
        setGoodsPrizes(state, payload) {
            state.goodsPrizes = payload
        },
        setShoppingCartGiftsData(state, payload) {
            state.shoppingCartGiftsData = payload
        },
        setCatalogListArchiveTotal(state, payload) {
            state.catalogListArchiveTotal = payload
        },
        setCatalogListArchive(state, payload) {
            state.catalogListArchive = payload
        },
        setArchiveSearchProcess(state, payload) {
            state.archiveSearchProcess = payload
        },
        setAccumulativeActionsList(state, payload) {
            state.accumulativeActionsList = payload
        },
        setAccumulativeActionId(state, payload) {
            state.accumulativeActionId = payload
        },
        setAccumulativeActionData(state, payload) {
            state.accumulativeActionData = payload
        },
        setSnData(state, payload) {
            state.snData = payload
        }
    },
    actions: {
        getCatalogList({
            commit,
            state,
        }, { self, config }) {
            self.$store.commit('setLoading', true)
            state.listLoading = true
            return new Promise((resolve, reject) => {
                const searchListOptions = JSON.parse(localStorage['searchListOptions'] || '{}')
                const catalogListOptions = JSON.parse(localStorage['catalogListOptions'] || '{}')
                const options = JSON.parse(self.search ? JSON.stringify(searchListOptions) : JSON.stringify(catalogListOptions))
                const typhoonId = config.analogs || config.duPriceoff ? self.$route.params.id : -1
                const actionId = self.actions ? self.$route.params.id : -1

                const dutype = config.duPriceoff ? 'duPriceoff' : undefined

                commit('setCatalogOptions', options)
                const params = {
                    typhoonId,
                    actionId,
                    dutype,
                    sortBy: options.sortBy,
                    sortDesc: options.sortDesc,
                    page: options.page,
                    rowsPerPage: options.itemsPerPage,
                    query: self.$route.query.query,
                    ...sessionStorage,
                    ...self.$route.query
                }
                if (!config.shoppingGuide) {
                    HTTP.get(self.$route.query.query ? `/catalog/search/` : `/catalog/list/${self.$route.params.id}`, {
                        params
                    })
                        .then(response => {
                            if (config.analogs) {
                                commit('setAnalogsList', response.data.content.data)
                                commit('setAnalogsListTotal', response.data.content.found_rows)
                            } else if (config.duPriceoff) {
                                commit('setDuPriceoffList', response.data.content.data)
                                commit('setDuPriceoffListTotal', response.data.content.found_rows)
                            } else {
                                commit('setCatalogList', response.data.content.data)
                                commit('setCatalogListTotal', response.data.content.found_rows)
                            }
                            commit('setCataloginFindIds', response.data.content.inFindIds)
                            self.$store.commit('setLoading', false)
                            state.listLoading = false
                            resolve({
                                items: response.data.content.data,
                                total: response.data.content.found_rows,
                                inFindIds: response.data.content.inFindIds
                            })
                        })
                        .catch(error => {
                            console.error(error)
                            self.$store.commit('setLoading', false)
                            state.listLoading = false
                            reject(error)
                        })
                } else {
                    state.listLoading = false
                    self.$store.commit('setLoading', false)
                    resolve({})
                }
            })
        },
        getFiltersList({
            commit,
            state
        }, self) {
            //self.$store.commit('setLoading', true)
            return new Promise((resolve, reject) => {
                const options = localStorage['catalogListOptions'] ? JSON.parse(localStorage['catalogListOptions']) : {}
                commit('setCatalogOptions', options)
                const typhoonId = self.analogs ? self.$route.params.id : -1
                const actionId = self.actions ? self.$route.params.id : -1
                const params = {
                    typhoonId,
                    actionId,
                    sortBy: options.sortBy,
                    sortDesc: options.sortDesc,
                    page: options.page,
                    rowsPerPage: options.itemsPerPage,
                    query: self.$route.query.query,
                    ...sessionStorage,
                    ...self.$route.query
                }

                state.filtersLoaded = false
                HTTP.get(`/catalog/filters-list/${self.$route.params.id || 0}`, { params })
                    .then(response => {
                        commit('setParentCats', response.data.content.parentCats)
                        commit('setCatalogListBrands', response.data.content.brands)
                        commit('setPriceMin', response.data.content.price_min)
                        commit('setPriceMax', response.data.content.price_max)
                        commit('setProps', response.data.content.props)
                        commit('setPropsData', response.data.content.propsData)
                        commit('setPropsNumeric', response.data.content.propsNumeric)
                        commit('setPropsNumericData', response.data.content.propsNumericData)
                        commit('setActionCats', response.data.content.actionCats)
                        //self.$store.commit('setLoading', false)
                        state.filtersLoaded = true
                        resolve({ content: response.data.content })
                    })
                    .catch(error => {
                        console.error(error)
                        self.$store.commit('setLoading', false)
                        reject(error)
                    })

            })
        },
        getShoppingGuideData({
            commit, state
        }, { id, filters }) {
            return new Promise((resolve, reject) => {
                state.listLoading = true
                HTTP.get('/shopping/get-shopping-guide-data?id=' + id + '&filters=' + filters)
                    .then(response => {
                        state.listLoading = false
                        if (response.data && response.data.content) {
                            commit('setShoppingGuideList', response.data.content.data.data)
                            commit('setShoppingGuideActionsList', response.data.content.actions.data.data)
                            commit('setShoppingGuideActionsListTotal', response.data.content.actions.total)
                            commit('setShoppingTasksPagination', response.data.content.pagination)
                            commit('setShoppingTasksFilters', response.data.content.filters)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        state.listLoading = false
                        reject(e)
                    })
            })
        },
        getTasksFilters({
            commit, state
        }, id) {
            return new Promise((resolve, reject) => {
                state.listLoading = true
                HTTP.get('/shopping/get-filters?id=' + id)
                    .then(response => {
                        state.listLoading = false
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        state.listLoading = false
                        reject(e)
                    })
            })
        },

        getTasksList({
            commit, state
        }, id) {
            return new Promise((resolve, reject) => {
                state.listLoading = true
                HTTP.get('/shopping/get-get-tasks-list?id=' + id)
                    .then(response => {
                        state.listLoading = false
                        if (response.data && response.data.content) {
                            commit('setShoppingGuideTasksList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        state.listLoading = false
                        reject(e)
                    })
            })
        },

        getClientsAutocomplete({
            commit,
            state
        }, query) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/clients-autocomplete?query=' + query,)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setClientsAutocomplete', response.data.content)
                            resolve(response.data.content)
                        } else {
                            commit('setClientsAutocomplete', [])
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getSertificates({
            commit,
            state
        }, typhoonId) {
            return new Promise((resolve, reject) => {
                commit('setSertificates', [])
                HTTP.get(`/catalog/get-certificates/${typhoonId}`)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setSertificates', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getContactsAutocomplete({
            commit,
            state
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/contacts-autocomplete?id=' + id,)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setContactsAutocomplete', response.data.content)
                            resolve(response.data.content)
                        } else {
                            commit('setContactsAutocomplete', [])
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getHotSliderItems({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/hot-slider-items',)
                    .then(response => {
                        if (response.data && response.data.content && response.data.content.data) {
                            commit('setSliderHotItems', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getNestedCats({
            commit
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/nested-cats?id=' + id)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setNestedCats', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getParentCats({
            commit
        }, ids) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/parent-cats', { ids })
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setParentCats', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getContentCatalogTree({
            commit, state
        }, cats) {
            state.catalogTreeLoading = true
            state.loading = true
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/content-tree', { cats })
                    .then(response => {
                        state.loading = false
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        state.loading = false
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getContentAvailableCats({
            commit, state
        }) {
            state.catalogTreeLoading = true
            state.loading = true
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/content-available-cats')
                    .then(response => {
                        state.loading = false
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        state.loading = false
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getActionsSliderItems({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/actions-items',)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setActionsItems', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getNewSliderItems({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/new-slider-items',)
                    .then(response => {
                        if (response.data && response.data.content && response.data.content.data) {
                            commit('setSliderNewItems', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getRecommendedItems({
            commit,
            state
        }, cols) {
            return new Promise((resolve, reject) => {
                const data = state.recommendedItems.map(el => el.typhoon_id)
                HTTP.post('/catalog/recommended-items', { existsIds: data, cols })
                    .then(response => {
                        if (response.data && response.data.content && response.data.content.data) {
                            commit('setRecommendedItems', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getVisitedItems({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/visited-items',)
                    .then(response => {
                        if (response.data && response.data.content && response.data.content.data) {
                            commit('setVisitedItems', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getBonusesItems({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/bonuses-items',)
                    .then(response => {
                        if (response.data && response.data.content && response.data.content.data) {
                            commit('setBonusesItems', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getProduct({
            commit
        }, typhoon_id) {
            return new Promise((resolve, reject) => {
                commit('setProductData', {})
                HTTP_PRODUCT.post('/get-product', { typhoon_id, lang_key: 'ua' })
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setProductData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getProductInfo({
            commit
        }, [typhoon_id, type, priceoff_id, group_id, skipResetData]) {
            return new Promise((resolve, reject) => {
                if (!skipResetData) {
                    commit('setProductInfoData', {})
                }
                HTTP.post('/catalog/get-product-info', { typhoonId: typhoon_id, type, priceoff_id, group_id })
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setProductInfoData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            commit('setProductInfoData', {})
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getOpenProductData({
            commit
        }, { typhoonId, onlyInYcSales, notPromo }) {
            return new Promise((resolve, reject) => {
                commit('setOpenProductData', {})
                HTTP.post('/catalog/get-open-product-data', { typhoonId, onlyInYcSales, notPromo })
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setOpenProductData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getAnalogs({
            commit
        }, [self, typhoonId, type]) {
            return new Promise((resolve, reject) => {
                const options = localStorage['catalogListOptions'] ? JSON.parse(localStorage['catalogListOptions']) : {}
                const params = {
                    typhoonId,
                    sortBy: options.sortBy,
                    sortDesc: options.sortDesc,
                    page: options.page,
                    rowsPerPage: options.itemsPerPage,
                    query: self.$route.query.query,
                    ...sessionStorage,
                    ...self.$route.query
                }
                HTTP.get('/catalog/get-analogs', { params })
                    .then(response => {
                        if (response.data && response.data.content && response.data.content.data) {
                            commit('setAnalogsTotal', response.data.content.found_rows)
                            commit('setAnalogsData', response.data.content.data)
                            resolve({
                                items: response.data.content.data,
                                total: response.data.content.found_rows,
                            })
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getPriceoffPhotos({
            commit
        }, { typhoon_id, priceoff_id }) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/priceoff-photos', { typhoon_id, priceoff_id })
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getShopsList({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/shopping/get-shops-list')
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setShopsList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getShopsFormats({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/shopping/get-shops-formats')
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setShopsFormats', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendInfoShopsData({ }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/shopping/send-info-shops-data', data)
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        confirmShop({ }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/shopping/confirm-shop', data)
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getGoodsPrizes({
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/get-goods-prizes',)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setGoodsPrizes', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getCatalogListArchive({
            commit,
            state,
        }, config) {
            // commit('setLoading', true)
            // state.listLoading = true
            commit('setArchiveSearchProcess', true)
            return new Promise((resolve, reject) => {
                const params = {
                    query: config.query,
                }
                HTTP.get(`/catalog/search-archive/`, {
                    params
                })
                    .then(response => {
                        commit('setCatalogListArchive', response.data.content.data)
                        commit('setCatalogListArchiveTotal', response.data.content.found_rows)
                        // commit('setLoading', false)
                        commit('setArchiveSearchProcess', false)
                        // state.listLoading = false
                        resolve({
                            items: response.data.content.data,
                            total: response.data.content.found_rows,
                            inFindIds: response.data.content.inFindIds
                        })
                    })
                    .catch(error => {
                        console.error(error)
                        // commit('setLoading', false)
                        // state.listLoading = false
                        commit('setArchiveSearchProcess', false)
                        reject(error)
                    })
            })
        },
        getAccumulativeActions({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/catalog/get-accumulative-actions/`)
                    .then(response => {
                        commit('setAccumulativeActionsList', response.data.content)
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        checkSN({
            commit,
        }, sn) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/catalog/check-sn/`, { sn })
                    .then(response => {
                        commit('setSnData', response.data.content)
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        openSearchGoods({
            commit,
        }, search) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/catalog/open-search-goods/`, { search })
                    .then(response => {
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        openGetGoodsData({
            commit,
        }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post(`/catalog/open-get-goods-data/`, data)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setOpenProductData', response.data.content.openData || {})
                            commit('setProductData', response.data.content.productData?.content || {})
                        }
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        }
    }
}